import { defineStore } from 'pinia'

export const useModalStore = defineStore('modal', () => {
  const show = ref(false)
  const title = ref('')
  const body = ref('')
  const cancelText = ref('')
  const confirmText = ref('')
  const onClose = ref<Function | null>(null)
  const onCancel = ref<Function | null>(null)
  const onConfirm = ref<Function | null>(null)

  watch(show, value => {
    if (!value) {
      title.value = ''
      body.value = ''
      cancelText.value = ''
      confirmText.value = ''
      onClose.value = () => {}
      onCancel.value = () => {}
      onConfirm.value = () => {}
    }
  })

  return {
    show,
    title,
    body,
    cancelText,
    confirmText,
    onClose,
    onCancel,
    onConfirm,
  }
})
